<template>
  <section class="inventions" :class="{ preview: previewMode }">
    <left-panel v-if="!previewMode" />
    <content-area v-if="$route.params.id" />
    <section v-else class="notification">
      <i a style="font-style: italic">Select an invention...</i>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import LeftPanel from './pageParts/LeftPanel.vue';
import ContentArea from './Content.vue';

export default {
  components: {
    LeftPanel,
    ContentArea
  },

  computed: {
    ...mapState({
      previewMode: s => s.previewMode
    })
  },
  watch: {
    async '$route.params.id'(prev, next) {
      if (!this.$route.params.id) {
        return;
      }
      this.$trackEvent(`Invnetion (${this.$route.params.id}) opened`);
    }
  },
  async created() {
    this.$trackEvent(`Invention stream opened`);
    await this.$store.dispatch('reports/myInventions/getCollection', { size: 1000 });
    await this.$store.dispatch('inventions/my');
  }
};
</script>

<style lang="scss" scoped>
.inventions {
  display: grid;
  grid-template-columns: minmax(0, 3fr) minmax(0, 8fr);
  grid-template-rows: minmax(0, 1fr);
  grid-gap: 2px;
  height: 100%;
  width: 100%;

  &.preview {
    grid-template-columns: 1fr;
  }
  .notification {
    display: grid;
    justify-content: center;
    align-content: center;
  }
}
</style>
