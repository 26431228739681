<template>
  <hub-inventions />
</template>

<script>
import Inventions from './../components/inventions/List.vue';

export default {
  components: {
    'hub-inventions': Inventions
  },
  mounted() {}
};
</script>
